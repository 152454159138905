import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import './react-draft-wysiwyg.css';

import './Entrada.css';


function Entrada() {

  const id = useParams()["id"] || 0;
  const navigate = useNavigate();
  const [save, setSave] = useState(true);
  const [blog, setBlog] = useState({id_blog: id, categorias:[]});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(()=>{
    getData();
  },[])
  async function getData(){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/entradas/get.php?id_blog='+id, 
      {
        headers: {
          'Content-type': 'application/json',
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      if(json["errAuth"]){
        return false;
      }
      if(json["blog"]){
        setBlog(json["blog"]);
        const content = convertFromRaw(JSON.parse(json["blog"]["data"]));
        console.log(content)
        setEditorState(EditorState.createWithContent(content));
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className='editorBlog blogUser'>
      <div className='efecto'>
        <div className='rotAjuste'>
          <div>
            <div className='volver' onClick={()=>navigate(-1)}>Regresar</div>
          </div>
          <div className='tBlog'>
            {blog.titulo}
          </div>
          <div className='categoriasBlog'>
            {blog.categorias?.map(categoria=>{
              return (
                <div className='etiquet' to={'/categorias/'+categoria.categoria} key={categoria.id_categoria}>{categoria.categoria}</div>
              )
            })
            }
          </div>
        </div>
      </div>
      <div className='previaBlog'>
        <Editor
          readOnly={true}
          toolbarClassName="sinbarra"
          editorState={editorState}
          onEditorStateChange={setEditorState}
        />
      </div>
    </div>
  );
}

export default Entrada;
