import { useState, useEffect } from 'react';
import useToken from '../useToken';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import './react-draft-wysiwyg.css';

import './Previa.css';


function Previa() {

  const { token, setToken } = useToken();
  const id = useParams()["id"] || 0;
  const navigate = useNavigate();
  const [save, setSave] = useState(true);
  const [blog, setBlog] = useState({id_blog: id, categorias:[]});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(()=>{
    getData();
  },[])
  async function getData(){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/get.php?id_blog='+id, 
      {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      if(json["errAuth"]){
        setToken({})
        return false;
      }
      if(json["blog"]){
        setBlog(json["blog"]);
        const content = convertFromRaw(JSON.parse(json["blog"]["data"]));
        console.log(content)
        setEditorState(EditorState.createWithContent(content));
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function guardar(){
    if(!save){
      return false
    }
    setSave(false);
    const content = convertToRaw(editorState.getCurrentContent());
    console.log(content);
    blog.data = JSON.stringify(content);
    let descripcion = '';
    let link = '';
    content.blocks.some(block=>{
      console.log(block.text, block.text.split(" "));
      if(block.text.split(" ").length==0 || block.text.split(" ")[0]==''){
        return false;
      }
      return block.text.split(" ").some(texto=>{
        if(descripcion.length + texto.length < 100){
          descripcion+= texto + ' ';
          return descripcion.length >= 100;
        }
        else{
          return true;
        }
      })
    })
    blog.link=blog.titulo.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, '').replaceAll(" ", "-");
    blog.descripcion=descripcion;
    console.log(blog)
    // return false
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/post.php', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      },
      body: JSON.stringify(blog)
    }).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json)
      if(json["errAuth"]){
        // setToken({})
        return false;
      }
      alert("Se ha guardado con éxito.")
      setSave(true);
      if(blog.id_blog==0){
        blog.id_blog = json["id_blog"];
        window.history.replaceState(null, blog.titulo, json["id_blog"])
      }
    })
    .catch((error) => {
      console.log(error);
      setSave(true);
    });
  }

  function eliminar(id_blog){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/delete.php?id_blog='+id_blog, 
      {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      if(json["errAuth"]){
        setToken({})
        return false;
      }
      if(json["status"]){
        navigate('/admin/')
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function publicar(e){
    console.log("publicando")
    let publicado = !e.target.checked;
    if(window.confirm("¿Deseas cambiar el estado de este blog de '"+(publicado ? "" : "No ")+"Publicado' a '"+(publicado ? "No " : "")+"Publicado'?")){
      fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/publicar.php?id_blog='+blog.id_blog+"&publicado="+(!publicado),
        {
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      ).then((response) => {/*console.log(response.text());*/return response.json()})
      .then((json) => {
        console.log(json);
        if(json["errAuth"]){
          setToken({})
          return false;
        }
        if(json["status"]){
          blog.publicado = !publicado;
          e.target.checked = !publicado;
        }
      })
      .catch((error) => {
        console.log(error);
      }); 
    }
    else{
      e.target.checked = publicado;
    }
  }

  return (
    <div className='editorBlog'>
      <div className='opcBlog'>
        {blog.id_blog!=0 &&
          <>
            <span className='delete-button' onClick={()=>{
              if(window.confirm('¿Estás seguro que deseas eliminar este blog?')){
                eliminar(blog.id_blog)
              }
            }}>Eliminar</span>
            <label className='conSwitch'>
              <span>Publicado</span>
              <input type='checkbox' defaultValue={blog?.publicado=='true'} onChange={publicar}/>
              <span>
                <div className='bola'></div>
              </span>
            </label>
          </>
        }
      </div>
      <div className='titPrev'>
        {blog.titulo}
      </div>
      <div className='previaBlog'>
        <Editor
          readOnly={true}
          toolbarClassName="sinbarra"
          editorState={editorState}
          onEditorStateChange={setEditorState}
        />
      </div>
    </div>
  );
}

export default Previa;
