import { Link } from 'react-router-dom';
import './Unete.css';
import logo from '../logo.svg';
import logoAlpha from '../logoAlpha.svg';

import diseño from '../img/disePer.png';
import gestion from '../img/gestion.png';
import escalabilidad from '../img/escalabilidad.png';
import soporte from '../img/soporte.png';

function Unete() {
  return (
    <div className='uneteAux'>
      <h1 className='titPrincipal'>Únete a Alpha. Tu Aliado en Comercio Electrónico y Logística.</h1>
      <div className='textoC'>
        <p>Descubre cómo Alpha puede transformar tu negocio llevando tu plataforma de comercio electrónico y operaciones logísticas al siguiente nivel. Con soluciones personalizadas y una gestión experta, estás a un paso de optimizar tu venta online y distribución.</p>
      </div>
      <div className='seccBene'>
        <div className='titSeccion'>Beneficios</div>
        <div className='contEti'>
          <div className='etiBene'>
            <div>Diseño Personalizado</div>
            <div>Crea una tienda en línea que refleje tu marca y atraiga a los clientes con la ayuda de nuestros expertos en diseño web.</div>
            <div><img className='imgBene' src={diseño} /></div>
          </div>
          <div className='etiBene'>
            <div>Gestión Eficiente</div>
            <div>Deja que nos ocupemos de la complejidad de las operaciones diarias con un sistema logístico integrado y automatizado.</div>
            <div><img className='imgBene' src={gestion} /></div>
          </div>
          <div className='etiBene'>
            <div>Escalabilidad</div>
            <div>A medida que tu negocio crece, Alpha crece contigo. Nuestras soluciones se adaptan a tus necesidades cambiantes.</div>
            <div><img className='imgBene' src={escalabilidad} /></div>
          </div>
          <div className='etiBene'>
            <div>Soporte Dedicado</div>
            <div>Nuestro equipo de soporte está aquí para ayudarte en cada paso del camino, garantizando que tu plataforma funcione sin problemas.</div>
            <div><img className='imgBene' src={soporte} /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unete;
