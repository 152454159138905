import { Link } from 'react-router-dom';
import './Cultura.css';
import logo from '../logo.svg';
import logoAlpha from '../logoAlpha.svg';
import mision from '../img/mision.svg';
import vision from '../img/vision.png';
import foto3 from '../img/foto3.svg';
import foto4 from '../img/foto4.svg';
import foto5 from '../img/foto5.svg';

function Cultura() {
  return (
    <div className='ver'>
      <h1 className='titPrincipal'>Nuestra Cultura</h1>
    <div className='textoC'>
      <p>En Alpha FullCommerce Mx, nos enorgullece cultivar una cultura empresarial sólida y positiva. Fomentamos la creatividad, la pasión por el trabajo y el aprendizaje continuo. Valoramos la diversidad y promovemos un ambiente inclusivo donde cada miembro del equipo puede prosperar y contribuir de manera significativa.</p>
      
      <p>Creemos en la importancia de mantener un equilibrio entre el trabajo y la vida personal, por lo que promovemos un ambiente de trabajo flexible y apoyamos el bienestar de nuestros empleados.</p>
    </div>
    <div className='myv divTarjetas'>
      <div>
        <div>
          <h2>Misión</h2>
          <p>Nuestra misión en Alpha FullCommerce es revolucionar el comercio electrónico a través de soluciones innovadoras y personalizadas. Nos comprometemos a brindar una experiencia excepcional a nuestros clientes, optimizando sus operaciones y potenciando su éxito en línea.</p>
        </div>
        <div className='imgDon'>
          <img className='imgInicio' src={mision}/>
        </div>
      </div>

      <div>
        <div>
          <h2>Visión</h2>
          <p>En Alpha FullCommerce, nuestra visión es ser reconocidos como líderes en la industria del comercio electrónico, impulsando la transformación digital de las empresas en todo el mundo. Buscamos crear un impacto significativo y duradero, mejorando la forma en que las empresas se conectan con sus clientes y crecen en el entorno digital.</p>
        </div>
        <div className='imgDon'>
          <img className='imgInicio' src={vision}/>
        </div>
      </div>
    </div>
      <h2 className='secTit'>Valores</h2>
      <div className='tarVal'>
        <div><span class="value">Innovación</span> Nos enfocamos en la continua mejora y la adopción de tecnologías avanzadas para ofrecer soluciones de vanguardia.</div>
        <div><span class="value">Colaboración</span> Trabajamos en equipo, tanto internamente como con nuestros clientes, para lograr resultados excepcionales.</div>
        <div><span class="value">Excelencia</span> Nos esforzamos por superar las expectativas y mantener los más altos estándares de calidad en todo lo que hacemos.</div>
        <div><span class="value">Integridad</span> Actuamos con honestidad, ética y transparencia en todas nuestras interacciones y decisiones.</div>
        <div><span class="value">Compromiso</span> Estamos comprometidos con el éxito y el crecimiento de nuestros clientes, empleados y la comunidad en general.</div>
      </div>
    </div>
  );
}

export default Cultura;
