import { useRef, useState, useEffect, useCallback} from 'react';
import { Link } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import './Contacto.css';
import facebook from '../logo.svg';
import mail from '../img/mail.svg';
import tele from '../img/telefono.svg';
import wa from '../img/what.svg';


function Contacto() {

  const apiKey = '6LfP71wmAAAAAHpaUWlNgy7MOBs-IcnFUmeJBrNc';

  const nombre = useRef();
  const correo = useRef();
  const telefono = useRef();
  const mensaje = useRef();
  const form = useRef();
  const captcha = useRef();

  function enviar(token){
    if(!(nombre.current.reportValidity() && 
      correo.current.reportValidity() && 
      telefono.current.reportValidity() && 
      mensaje.current.reportValidity()))
      return false;
    const datos = {};
    datos.token = token;
    datos.nombre = nombre.current.value;
    datos.correo = correo.current.value;
    datos.telefono = telefono.current.value;
    datos.mensaje = mensaje.current.value;
    console.log(datos);

    fetch("https://www.alpha-fullcommerce.mx/alphaAPI/mensajes/set.php", {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(datos)
      })
    .then(response=> response.json())
    .then(json=>{
      if(json["status"]){
        window.alert("El mensaje ha sido enviado.")
        form.current.reset();
      }
    })
    .catch((error) => {
      console.error(error.message);
    });

  }

  function onChange(value) {
    console.log("Captcha value:", value);
  }


  return (
    <section className='contactanos'>
      <div className='titContacto'><h1>Contacto</h1></div>
      <div className='titBajo'>Déjanos tus preguntas y uno de nuestros especialistas se comunicará para atenderte.</div>
      <div>
        <form ref={form} className='formContact'>
          <div>
            <div>
              <input className='minChat' type='text' ref={nombre} required placeholder='Nombre*'/>
            </div>
          </div>
          <div>
            <div>
              <input className='minChat' type='email' ref={correo} required placeholder='Correo*'/>
            </div>
          </div>
          <div>
            <div>
              <input className='minChat' type='tel' ref={telefono} placeholder='Teléfono (Opcional)'/>
            </div>
          </div>
          <div>
            <div>
              <textarea className='minChat' ref={mensaje} required placeholder='Mensaje*'></textarea>
            </div>
          </div>
           <GoogleReCaptchaProvider reCaptchaKey={apiKey}>
            <Captcha funcion={enviar} />
          </GoogleReCaptchaProvider>
        </form>
        <div className='socials'>
          <div>
            <div>
              <span className='medios'>¡También puedes contactarnos por estos medios!</span>
            </div>
          </div>
          <div>
            <div>
              <div className='imgSoc'>
                <Link to='mailto:comercial@alpha-fullcommerce.mx?subject=Solicitud'><img src={mail}/></Link>
              </div>
              <div>Correo</div>
            </div>
            {/*<div>
              <div className='imgSoc'>
                <img src={tele}/>
              </div>
              <div>Tel. (55) 8797 6765</div>
            </div>*/}
            <div>
              <div className='imgSoc'>
                <Link to='https://wa.me/525617210407'><img src={wa}/></Link>
              </div>
              <div>WhatsApp</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const Captcha = ({funcion}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('mail');
    console.log(token);
    // Do whatever you want with the token
    funcion(token);
  }, [executeRecaptcha]);

  return (
    <div className='botones botonAzul'>
      <div onClick={handleReCaptchaVerify} className='sig'>Enviar</div>
    </div>
  );
};

export default Contacto;
