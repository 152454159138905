import { Link } from 'react-router-dom';
import './Servicios.css';
import logo from '../logo.svg';
import logoAlpha from '../logoAlpha.svg';
import mundo from '../img/mundo.svg';
import poste from '../img/direction.svg';
import cajasIzquierda from '../img/cajasIzq.svg';
import cajasDerecha from '../img/cajasDer.svg';
import avionIzquierda from '../img/avionIzq.svg';
import avionDerecha from '../img/avionDer.svg';


function Servicios() {
  return (
    <div className='servicios'>
      <div className='imgServicios'>
          <img className='imgMundo' src={mundo}/>
          <img className='imgPoste' src={poste}/>
          <img className='imgCajas1' src={cajasIzquierda}/>
          <img className='imgCajas2' src={cajasDerecha}/>
          <img className='imgAvion1' src={avionIzquierda}/>
          <img className='imgAvion2' src={avionDerecha}/>
      </div>
      <section id='fullcommerce'>
        <div className='titSeccion'>Fullcommerce</div>
        <div className='divTarjetas'>
          <div data-aos='fade-up'>
            <h4>Alpha FC Mx diseña, crea, implementa y gestiona tu plataforma de comercio electrónico </h4>
            <div className='lista'>
              <div><div className='pointer'></div><div>Diseño de la plataforma</div></div>
              <div><div className='pointer'></div><div>Desarrollo de la plataforma</div></div>
              <div><div className='pointer'></div><div>Integración de medios de pago</div></div>
              <div><div className='pointer'></div><div>Creación de catálogos de productos</div></div>
              <div><div className='pointer'></div><div>Configuración de envíos</div></div>
              <div><div className='pointer'></div><div>Capacitación y soporte</div></div>
            </div>
          </div>
          <div data-aos='fade-up'>
            <h4>Alpha FC Mx diseña y gestiona la logística de tu empresa</h4>
            <div className='textJ'>Ofrecemos servicios logísticos a traves de soluciones personalizadas para empresas que necesitan gestionar su cadena de suministro de manera eficiente. Los servicios lógisticos de Alpha FC Mx incluyen:</div>
            <div className='lista'>
              <div><div className='pointer'></div><div>Gestión de inventario</div></div>
              <div><div className='pointer'></div><div>Cumplimiento de pedidos</div></div>
              <div><div className='pointer'></div><div>Servicio al cliente</div></div>
              <div><div className='pointer'></div><div>Gestión de devoluciones</div></div>
              <div><div className='pointer'></div><div>Integración de sistemas</div></div>
              <div><div className='pointer'></div><div>Mejora la experiencia del cliente</div></div>
            </div>
          </div>
          <div data-aos='fade-up'>
            <h4>Alpha FC Mx te ofrece los mejores servicios de paquetería al mejor costo</h4>
            <div className='textJ'>Algunas de las soluciones en paquetería que ofrecemos incluyen:</div>
            <div className='lista'>
              <div><div className='pointer'></div><div>Mensajería y paquetería local</div></div>
              <div><div className='pointer'></div><div>Mensajería y paquetería nacional</div></div>
              <div><div className='pointer'></div><div>Carga LTL</div></div>
            </div>
          </div>
        </div>
      </section>
      <section id='nearshoring'>
        <div className='titSeccion'>Nearshoring</div>
        <div className='divTarjetas'>
            <div data-aos='fade-up'>
            <h4>El Nearshoring es un modelo de negocio que se ha vuelto cada vez más popular entre las empresas de tecnología y otros sectores en los últimos años. Este modelo se refiere a la externalización de servicios y actividades empresariales a países cercanos geográficamente, con el objetivo de aprovechar los beneficios de costos, calidad y accesibilidad que ofrecen estos países.</h4>
            <div className='textJ'>En Alpha FC Mx ofrecemos diversos servicios para hacer el proceso de nearshoring más sencillo y confiable en el menor tiempo posible:</div>
            <div className='lista'>
              <div><div className='pointer'></div><div>Softlanding</div></div>
              <div><div className='pointer'></div><div>4PL</div></div>
              <div><div className='pointer'></div><div>Personal Especializado</div></div>
              <div><div className='pointer'></div><div>IMMEX</div></div>
            </div>
          </div>
        </div>
      </section>
      <section id='centro'>
        <div className='titSeccion'>Almacén / Centro de Distribución</div>
        <div className='divTarjetas'>
            <div data-aos='fade-up'>
            <h4>Contamos con instalaciones modernas y seguras que cumplen con los más altos estándares de calidad. Nuestro equipo está capacitado para manejar una amplia gama de productos y ofrecemos soluciones de almacenamiento y distribución flexibles, adaptadas a sus necesidades específicas, tales como:</h4>
            <div className='lista'>
              <div><div className='pointer'></div><div>Gestión de inventarios</div></div>
              <div><div className='pointer'></div><div>Picking</div></div>
              <div><div className='pointer'></div><div>Packing</div></div>
              <div><div className='pointer'></div><div>Etiquetado</div></div>
              <div><div className='pointer'></div><div>Gestión de devoluciones</div></div>
              <div><div className='pointer'></div><div>Planificación de rutas</div></div>
              <div><div className='pointer'></div><div>Gestión de flota de transporte</div></div>
              <div><div className='pointer'></div><div>Seguimiento de enviós</div></div>
              <div><div className='pointer'></div><div>Coordinación de entrega a clientes (B2B & B2C)</div></div>
              <div><div className='pointer'></div><div>Notificación de entregas</div></div>
              <div><div className='pointer'></div><div>Control de calidad</div></div>
              <div><div className='pointer'></div><div>Logística inversa</div></div>
            </div>
          </div>
        </div>
      </section>
      <section id='transporte'>
        <div className='titSeccion'>Transporte de Carga</div>
        <div className='divTarjetas'>
          <div data-aos='fade-up'>
            <h4>¡Nos encargamos de la distribución y entrega de tus mercancias!</h4>
            <div className='textJ'>Contamos con una amplia flota de vehículos, tanto propios como de socios estratégicos, para garantizar la entrega puntual y segura de sus productos en cualquier destino. Ofrecemos soluciones de transporte terrestre, marítimo y aéreo, adaptadas a sus necesidades y presupuesto.</div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Servicios;
