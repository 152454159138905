import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Categorias.css';
import defaultImg from '../img/logoAlpha.svg';
import { Miniatura } from '../Entradas/Entradas.js';


function Categorias() {
  const categoria = useParams()["categoria"];
  const [blogs, setBlogs] = useState([]);
  useEffect(()=>{
    getData();
  },[])
  async function getData(){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/entradas/get.php?categoria='+categoria, 
      {
        headers: {
          'Content-type': 'application/json'
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      setBlogs(json["blogs"]);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  return (
      <div className='blogsAdm'>
      {blogs.length==0 &&
        <div>
          <div>No hay entradas con la categoría <b>{categoria}</b>.</div>
        </div>
      }
      { 
        blogs?.map(blog=>{
          return(
            <Miniatura key={blog.id_blog} blog={blog}/>
          )
        })
      }
      </div>
    );
}

export default Categorias;
