import { Link } from 'react-router-dom';
import './Inicio.css';
import logo from '../logo.svg';
import logoAlpha from '../logoAlpha.svg';
import foto1 from '../img/foto1.svg';
import foto2 from '../img/foto2.svg';
import foto3 from '../img/foto3.svg';
import foto4 from '../img/foto4.svg';
import foto5 from '../img/foto5.svg';

function Inicio() {
  return (
    <div className='inicio'>
      <div className='first'>
        <h1 className='titPrincipal'>Tu aliado estratégico en todos los procesos de comercio electrónico y logística.</h1>
        <div className='textoC'>
          Alpha FC Mx diseña y gestiona tu plataforma de comercio electrónico y tus procesos logísticos mientras te enfocas en tu negocio.
        </div>
        <div className='botonAzul'>
          <Link to='/contacto'>Contáctanos</Link>
        </div>
      </div>
      <section>
        <div data-aos='fade-right'>
          <h2>Te acompañamos en el diseño, creación, implementación y gestión de tu tienda en línea.</h2>
          <div className='textJ'>Creamos soluciones flexibles adaptadas a tus necesidades.</div>
          <div className='botonAzul'>
            <Link to='/contacto'>Contáctanos</Link>
          </div>
        </div>
        <div className='divImg' data-aos='fade-up'>
          <img className='imgInicio' src={foto1}/>
        </div>
      </section>
      <section>
        <div data-aos='fade-left'>
          <h2>Si planeas iniciar operaciones en México, te ayudaremos a que el aterrizaje sea suave y confiables.</h2>
          <div>Sabemos que el tiempo es dinero, por lo que ofrecemos diversos servicios enfocados a facilitar el establecimiento y puesta en marcha de las operaciones de nuestros clientes en el menor tiempo posible.</div>
          <div className='botonAzul'>
            <Link to='/contacto'>Contáctanos</Link>
          </div>
        </div>
        <div className='divImg' data-aos='flip-left'>
          <img className='imgInicio' src={foto3}/>
        </div>
      </section>
      <section>
        <div data-aos='fade-right'>
          <h2>Contamos con una amplia red de centros de distribución en toda la República Mexicana.</h2>
          <div>Nacimos en la logística tradicional y evolucionamos al fullcommerce, por lo que tenemos amplia experiencia en el manejo de centros de distribución.</div>
          <div className='botonAzul'>
            <Link to='/contacto'>Contáctanos</Link>
          </div>
        </div>
        <div className='divImg' data-aos="fade-down-right">
          <img className='imgInicio' src={foto2}/>
        </div>
      </section>
      <section>
        <div data-aos='fade-left'>
          <h2>Desde motocicletas hasta contenedores.</h2>
          <div>Nuestros servicios de distribución se adaptan a cualquier negocio y cadena de suministro; creamos la solución más eficiente y económica para la entrega a tus clientes finales.</div>
          <div className='botonAzul'>
            <Link to='/contacto'>Contáctanos</Link>
          </div>
        </div>
        <div className='divImg' data-aos="fade-down-left">
          <img className='imgInicio' src={foto4}/>
        </div>
      </section>
      <section>
        <div data-aos='fade-right'>
          <h2>Crea o transforma tu tienda en línea y tu logística con un socio de fullcommerce.</h2>
          <div>Creamos soluciones flexibles adaptadas a tus necesidades.</div>
          <div className='botonAzul'>
            <Link to='/contacto'>Contáctanos</Link>
          </div>
        </div>
        <div className='divImg' data-aos='flip-right'>
          <img className='imgInicio' src={foto5}/>
        </div>
      </section>
    </div>
  );
}

export default Inicio;
