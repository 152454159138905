import logo from './logo.svg';
import useToken from './useToken';
import logoAlpha from './logoAlpha.svg';
import flecha from './img/flecha.png';
import desp from './img/desp.png';
import burger from './img/burger.png';
import logoF from './img/logoFoot.svg';
import engrane from './img/engrane.svg';
import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, NavLink, useLocation, Outlet } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import './App.css';
import Inicio from './Inicio/Inicio.js';
import Contacto from './Contacto/Contacto.js';
import Servicios from './Servicios/Servicios.js';
import Entradas from './Entradas/Entradas.js';
import Categorias from './Categorias/Categorias.js';
import Entrada from './Entrada/Entrada.js';
import Login from './Login/Login.js';
import Admin from './Admin/Admin.js';
import Blog from './Blog/Blog.js';
import Previa from './Previa/Previa.js';
import Cultura from './Cultura/Cultura.js';
import Ambiente from './Ambiente/Ambiente.js';
import Preguntas from './Preguntas/Preguntas.js';
import Bolsa from './Bolsa/Bolsa.js';
import Unete from './Unete/Unete.js';

function App() {
  return (
    <BrowserRouter>
      <Contenido/>
    </BrowserRouter>
  );
}
const Contenido = ()=>{
  const [menu, setMenu] = useState(false);
  const [nav, setNav] = useState('');
  const [despliega, setDespliega] = useState(false);
  const [claseInicio, setClaseInicio] = useState("");
  const [anterior, setAnterior] = useState("");
  const location = useLocation();

  useEffect(() => {
    setMenu(false);
    if(location.pathname.split("/").length!=0){
      setAnterior(location.pathname.split("/")[1])
      if(location.pathname.split("/")[1] != "servicios"){
        window.scrollTo(0,0);
      }
      else{
        if(anterior != "servicios"){
          window.scrollTo(0,0);
        }
      }
      // console.log
      setClaseInicio(location.pathname.split("/")[1]);
    }
  }, [location]);

  window.onscroll = function (){
    if(window.scrollY == 0){
      setNav('')
    }
    else if(nav==''){
      setNav('fix')
    }
  }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const nav = document.querySelector("nav").offsetHeight;
    const yOffset = -(nav + 10); 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

  return (
    <div className={"contenedor cInicio "+claseInicio}>
      <nav className={(menu ? 'muestra' : '')+(nav ? ' fix' : '')}>
        <Link className='logo' to='/'>
          <img src={logoAlpha}/>
        </Link>
        <div className={'serviciosNav'+(despliega?.servicios ? ' desplegar' : '')} onClick={(e)=>{
          setDespliega({...despliega, servicios : !despliega?.servicios});
        }}>
          <div>
            <NavLink to='/servicios'>Servicios</NavLink>
            <span className='flechaFalsa'>
              <img src={desp}/>
            </span>
          </div>
          <div className='despliega'>
            <Link scroll={el => scrollWithOffset(el)} smooth to='/servicios#fullcommerce'>Fullcommerce</Link>
            <Link scroll={el => scrollWithOffset(el)} smooth to='/servicios#nearshoring'>Nearshoring</Link>
            <Link scroll={el => scrollWithOffset(el)} smooth to='/servicios#centro'>Centro de distribución</Link>
            <Link scroll={el => scrollWithOffset(el)} smooth to='/servicios#transporte'>Transporte de carga</Link>
          </div>
        </div>

        <div className={'serviciosNav'+(despliega?.somos ? ' desplegar' : '')} onClick={(e)=>{
          setDespliega({...despliega, somos : !despliega?.somos});
        }}>
          <div>
            <Link>Somos Alpha</Link>
            <span className='flechaFalsa'>
              <img src={desp}/>
            </span>
          </div>
          <div className='despliega'>
            <Link to='/cultura'>Cultura Alpha</Link>
            <Link to='/ambiente'>Responsabilidad social y con el medio ambiente</Link>
            <Link to='/unete'>{/*<img className='engra' src={engrane}/>*/}Únete a Alpha</Link>
            <Link className='subRa'><img className='engra' src={engrane}/>Alianzas</Link>
            <Link className='subRa'><img className='engra' src={engrane}/>Noticias</Link>
            <Link to='/blog'>Blogs</Link>
          </div>
        </div>

        <div className={'serviciosNav'+(despliega?.contacto ? ' desplegar' : '')} onClick={(e)=>{
          setDespliega({...despliega, contacto : !despliega?.contacto});
        }}>
          <div>
            <Link>Contacto</Link>
            <span className='flechaFalsa'>
              <img src={desp}/>
            </span>
          </div>
          <div className='despliega'>
            <Link to='/contacto'>Contáctanos</Link>
            <Link to='/preguntas'>Preguntas Frecuentes</Link>
            <Link to='/bolsa'>Bolsa de Trabajo</Link>
          </div>
        </div>
        <div className='hamburguesa' onClick={()=>{
          setMenu(!menu);
        }}>
          <img src={burger}/>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Inicio/>}></Route>
        <Route path="/contacto" element={<Contacto/>}></Route>
        <Route path="/servicios" element={<Servicios/>}></Route>
        <Route path="/blog" element={<Entradas/>}></Route>
        <Route path="/blog/:id" element={<Entrada/>}></Route>
        <Route path="/blog/:id/:link" element={<Entrada/>}></Route>
        <Route path="/categorias/:categoria" element={<Categorias/>}></Route>
        <Route path="/admin" element={<AdminNav/>}>
          <Route path="" element={<Admin/>}></Route>
          <Route path="blog" element={<Blog/>}></Route>
          <Route path="blog/:id" element={<Blog/>}></Route>
          <Route path="blog/previa/:id" element={<Previa/>}></Route>
        </Route>
        <Route path="/cultura" element={<Cultura/>}></Route>
        <Route path="/ambiente" element={<Ambiente/>}></Route>
        <Route path="/preguntas" element={<Preguntas/>}></Route>
        <Route path="/bolsa" element={<Bolsa/>}></Route>
        <Route path="/unete" element={<Unete/>}></Route>
      </Routes>
      
      <footer>
        <div>
          <div className='logoFooter' ><img src={logoF}/></div>
          <div className='footIzq'>
            <div>Contamos con años de experiencia en logística.</div>
            <div>Nuestro equipo multidisciplinar ha sido reconocido como uno de los mejores.</div>
            <div>Confíanos tu proyecto y dale lo mejor.</div>
          </div>
        </div>
        <div>
          <div>Te invitamos a explorar nuestro sitio web y descubrir como nuestras soluciones de fullcommerce pueden mejorar tu negocio y aumentar tu productividad ¡Vamos!</div>
          <div>Aviso de privacidad</div>
        </div>
        <div>
          <div className='subir' onClick={()=>{
            window.scrollTo({top:0, behavior:'smooth'})
          }}>
            <img src={flecha}/>
          </div>
          <NavLink to='/'>Alpha Fullcommerce</NavLink>
          <NavLink to='/servicios'>Servicios</NavLink>
          <NavLink to='/about'>Somos Alpha</NavLink>
          <NavLink to='/contacto'>Contacto</NavLink>
        </div>
      </footer>
    </div>)
}

const AdminNav = ()=>{

  const { token, setToken } = useToken();
  
  function logout(){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/logout.php', 
      {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
      ).then((response) => {/*console.log(response.text());*/return response.json()})
      .then((json) => {
        if(json["estado"]){
          setToken({estado:'cerrar'});
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  if(token)
    return(
      <>
        <div className='navAdmin'>
          <Link to='/admin/'>Blogs</Link>
          <span onClick={logout}>Cerrar Sesión</span>
        </div>
        <Outlet/>
      </>
    )
  else
    return(
      <>
        <Login setToken={setToken}/>
      </>
    )
}

export default App;
