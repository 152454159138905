import { Link } from 'react-router-dom';
import './Preguntas.css';
import logo from '../logo.svg';
import logoAlpha from '../logoAlpha.svg';
import foto1 from '../img/foto1.svg';
import foto2 from '../img/foto2.svg';
import foto3 from '../img/foto3.svg';
import foto4 from '../img/foto4.svg';
import foto5 from '../img/foto5.svg';

function Preguntas() {
  return (
    <div className='preguntasF ver'>
        <h1 className='titPrincipal'>Preguntas Frecuentes</h1>
        <div>
          <p>¿Qué es el FullCommerce?</p>
          <p>El FullCommerce es un modelo de negocio en el que una empresa se encarga de gestionar todas las etapas del proceso de comercio electrónico, desde la creación y diseño de la tienda en línea hasta el almacenamiento, envío y servicio al cliente. Es una solución integral que permite a las empresas centrarse en su negocio principal mientras delegan las tareas relacionadas con el comercio electrónico en un socio especializado.</p>
        </div>
        <div>
          <p>¿Cuáles son los beneficios de utilizar los servicios de FullCommerce?</p>
          <p>Al utilizar los servicios de FullCommerce, las empresas pueden aprovechar la experiencia y el conocimiento de profesionales especializados en el comercio electrónico. Esto les permite contar con una plataforma en línea eficiente y segura, una gestión logística optimizada y un servicio al cliente de calidad. Además, el FullCommerce puede ayudar a reducir costos y tiempos de implementación, así como a mejorar la experiencia de compra para los clientes.</p>
        </div>
        <div>
          <p>¿Qué servicios ofrece Alpha FullCommerce MX?</p>
          <p>Alpha FullCommerce MX ofrece una amplia gama de servicios en el ámbito del comercio electrónico. Esto incluye el diseño y desarrollo de tiendas en línea personalizadas, la gestión de inventario, el procesamiento de pagos, la logística de envío, el servicio al cliente y el análisis de datos para optimizar el rendimiento de la tienda en línea.</p>
        </div>
        <div>
          <p>¿Cómo puedo comenzar a utilizar los servicios de Alpha FullCommerce MX?</p>
          <p>Puedes comenzar a utilizar los servicios de Alpha FullCommerce MX poniéndote en contacto con nuestro equipo a través de nuestro sitio web o por correo electrónico. Nuestros expertos estarán encantados de discutir tus necesidades específicas y proporcionarte una solución personalizada que se ajuste a tu negocio.</p>
        </div>
        <div>
          <p>¿Qué tipo de negocios son adecuados para utilizar los servicios de Alpha FullCommerce MX?</p>
          <p>Alpha FullCommerce MX se adapta a diferentes tipos de negocios, desde pequeñas startups hasta grandes empresas. Es especialmente beneficioso para aquellos negocios que deseen expandir su presencia en línea, optimizar sus operaciones comerciales y brindar una experiencia de compra excepcional a sus clientes.</p>
        </div>
        <div>
          <p>¿Cómo se integra Alpha FullCommerce MX con los sistemas existentes de mi empresa?</p>
          <p>Alpha FullCommerce MX se especializa en la integración de sistemas. Trabajamos en estrecha colaboración con tu empresa para comprender tus sistemas y procesos existentes, y luego desarrollamos soluciones personalizadas que se integren perfectamente con ellos. Nuestro objetivo es simplificar y optimizar tus operaciones comerciales.</p>
        </div>
        <div>
          <p>¿Ofrece Alpha FullCommerce MX soporte técnico y servicio al cliente?</p>
          <p>Sí, Alpha FullCommerce MX proporciona soporte técnico y servicio al cliente. Nuestro equipo está disponible para responder tus preguntas, brindar asistencia técnica y resolver cualquier problema que puedas enfrentar. Nos preocupamos por tu satisfacción y nos esforzamos por brindar un servicio de alta calidad.</p>
        </div>
    </div>
  );
}

export default Preguntas;
