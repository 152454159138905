import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Entradas.css';
import defaultImg from '../img/logoAlpha.svg';
import desp from '../img/desp.png';


function Entradas() {

  const [blogs, setBlogs] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [orden, setOrden] = useState('recientes');
  const [autores, setAutores] = useState([]);
  const [verCat, setVerCat] = useState(false);
  const [verAutor, setVerAutor] = useState(false);
  const [filtroCat, setFiltroCat] = useState([]);
  const [filtroAutor, setFiltroAutor] = useState([]);
  useEffect(()=>{
    getData();
  },[])
  useEffect(()=>{
    const aux = blogs.reverse()
    setBlogs([...aux]);
  }, [orden])
  async function getData(){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/entradas/get.php', 
      {
        headers: {
          'Content-type': 'application/json'
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      setBlogs(json["blogs"]);
      setCategorias(json["categorias"]);
      setAutores(json["autores"]);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  return (
      <div>
      <div className='fil'>
        <div>
          <div>Ordenar por:</div>
          <div className='orden'>
            <span className={orden == 'recientes' ? 'sel' : ''} onClick={()=>{
              setOrden('recientes');
            }}>Más recientes</span>
            <span className={orden == 'viejos' ? 'sel' : ''} onClick={()=>{
              setOrden('viejos');
            }}>Más viejos</span>
          </div>
        </div>
        <div className='filtrosBlog'>
          <div>
            <div onClick={()=>{
              setVerCat(!verCat);
            }}>
              <span>Categorías</span>
              <span className={'flechaFalsa'+(verCat ? ' rotar' : '')}>
                <img src={desp}/>
              </span>
            </div>
            {verCat &&
              <div className='filtroCat'>
                {categorias.map(categoria=>{
                  return(
                    <label>
                      <input type='checkbox' onChange={(e)=>{
                        if(!e.target.checked && filtroCat.includes(categoria.categoria)){
                          setFiltroCat(filtroCat.filter(a=>{
                            return a!=categoria.categoria
                          }))
                        }
                        else if(e.target.checked){
                          setFiltroCat([...filtroCat, categoria.categoria])
                        }
                      }}/>
                      <span>
                        <span>{categoria.categoria}</span>
                        <span>({categoria.total})</span>
                      </span>
                    </label>
                  );
                })}
              </div>
            }
          </div>
          <div>
            <div onClick={()=>{
              setVerAutor(!verAutor);
            }}>
              <span>Autores</span>
              <span className={'flechaFalsa'+(verAutor ? ' rotar' : '')}>
                <img src={desp}/>
              </span>
            </div>
            {verAutor &&
              <div className='filtroCat'>
                {autores.map(autor=>{
                  return(
                    <label>
                      <input type='checkbox' onChange={(e)=>{
                        if(!e.target.checked && filtroAutor.includes(autor.autor)){
                          setFiltroAutor(filtroAutor.filter(a=>{
                            return a!=autor.autor
                          }))
                        }
                        else if(e.target.checked){
                          setFiltroAutor([...filtroAutor, autor.autor])
                        }
                      }}/>
                      <span>
                        <span>{autor.autor}</span>
                        <span>({autor.total})</span>
                      </span>
                    </label>
                  );
                })}
              </div>
            }
          </div>
        </div>
        </div>

        <div className='blogsAdm blogsUser'>
        {blogs.length==0 &&
          <div>
            <div>Aún no hay entradas en el blog.</div>
          </div>
        }
        { 
          blogs?.map(blog=>{
            const enCat = filtroCat.some(f=>{
              return blog.categorias.some(cat=>{
                return cat.categoria==f;
              });
            })
            const enAutor = filtroAutor.includes(blog.autor)
            if(
              (filtroCat.length == 0 || enCat) &&
              (filtroAutor.length == 0 || enAutor)
            )
            return(
              <Miniatura key={blog.id_blog} blog={blog}/>
            )
          })
        }
        </div>
      </div>
    );
}

const Miniatura = ({blog})=>{
  const dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"];
  const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  function formatoFecha(fecha){
    let date = new Date(fecha.split(" ")[0].replaceAll("-", "/"));
    return dias[date.getDay()] + " " + date.getDate() + ", " + meses[date.getMonth()] + " " + date.getFullYear();
  }
  const fecha = formatoFecha(blog.fecha);
  return (
    <div className='tarBlog' key={blog.id_blog}>
      <Link to={'/blog/'+blog.id_blog+"/"+blog.link}>
        <div className='imgBlog'>
          <img src={blog.ruta ? 'https://www.alpha-fullcommerce.mx/alphaAPI/img/'+blog.ruta : defaultImg}/>
        </div>
        <div className='categoriasBlog'>
          {blog.categorias?.map(categoria=>{
            return (
              <div className='etiquet' key={categoria.id_categoria}>{categoria.categoria}</div>
            )
          })
          }
        </div>
        <div className='tituloBlog titB'>{blog.titulo}</div>
        <div className='fechaBlog'>{fecha}</div>
      </Link>
    </div>
  )
}

export default Entradas;
export {Miniatura};