import { Link } from 'react-router-dom';
import './Bolsa.css';
import logo from '../logo.svg';
import logoAlpha from '../logoAlpha.svg';
import mision from '../img/mision.svg';
import vision from '../img/vision.png';
import foto3 from '../img/foto3.svg';
import foto4 from '../img/foto4.svg';
import foto5 from '../img/foto5.svg';
import home from '../img/house.png';
import edificio from '../img/edificio.png';
import proyecto from '../img/proyecto.png';
import tiempo from '../img/tiempo.png';
import wa from '../img/what.svg';

function Bolsa() {
  return (
    <div className='ver'>
      <h1 className='titPrincipal'>Gracias por ver nuestras ofertas de trabajo. ¿Ves algo que te interesa?</h1>
      <div className='contVaca'>
        <div className='titNo'></div>
        <div className='vacante'>
          <div>
             <div className='auxNO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
            <div>Programador Full Stack</div>
          </div>
          <div><img className='imgIco' src={home} />Remote</div>
          <div className='dobleD'>
            <div className='movil'><img className='imgIco' src={home} />Remote</div>
            <div><img className='imgIco' src={tiempo} />Tiempo Completo</div>
            <div><img className='imgIco' src={proyecto} />Por Proyecto</div>
          </div>
          <div className='WAO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
        </div>
        <div className='vacante'>
          <div>
             <div className='auxNO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
            <div>Analista de tráfico</div>
          </div>
          <div><img className='imgIco' src={edificio} />Tultitlán, Estado de México</div>
          <div><img className='imgIco' src={tiempo} />Tiempo Completo</div>
          <div className='WAO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
        </div>
        <div className='vacante'>
          <div>
             <div className='auxNO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
            <div>Ejecutivo de atención a clientes</div>
          </div>
          <div><img className='imgIco' src={edificio} />Tultitlán, Estado de México</div>
          <div><img className='imgIco' src={tiempo} />Tiempo Completo</div>
          <div className='WAO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
        </div>
        <div className='vacante'>
          <div>
             <div className='auxNO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
            <div>Etiquetador</div>
          </div>
          <div><img className='imgIco' src={edificio} />Tultitlán, Estado de México</div>
          <div><img className='imgIco' src={tiempo} />Tiempo Completo</div>
          <div className='WAO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
        </div>
        <div className='vacante'>
          <div>
             <div className='auxNO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
            <div>Surtidor</div>
          </div>
          <div><img className='imgIco' src={edificio} />Tultitlán, Estado de México</div>
          <div><img className='imgIco' src={tiempo} />Tiempo Completo</div>
          <div className='WAO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
        </div>
        <div className='vacante'>
          <div>
             <div className='auxNO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
            <div>Auxiliar general</div>
          </div>
          <div><img className='imgIco' src={edificio} />Tultitlán, Estado de México</div>
          <div><img className='imgIco' src={tiempo} />Tiempo Completo</div>
          <div className='WAO'><Link to='https://wa.me/525617210407'><img className='imgIco WA' src={wa}/></Link></div>
        </div>
      </div>
    </div>
  );
}

export default Bolsa;
