import { useState, useEffect, useRef } from 'react';
import useToken from '../useToken';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import './react-draft-wysiwyg.css';

import './Blog.css';
import subir from '../img/flecha.png';


function Blog() {

  const { token, setToken } = useToken();
  const id = useParams()["id"] || 0;
  const navigate = useNavigate();
  const [save, setSave] = useState(true);
  const [blog, setBlog] = useState({id_blog: id, categorias:[]});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [categorias, setCategorias] = useState([]);
  const [sugerencias, setSugerencias] = useState([]);
  const [publicado, setPublicado] = useState(false);
  const [imagen, setImagen] = useState({});
  const inputCat = useRef();

  useEffect(()=>{
    getData();
  },[])
  async function getData(){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/get.php?id_blog='+id, 
      {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      if(json["errAuth"]){
        setToken({})
        return false;
      }
      if(json["blog"]){
        setBlog(json["blog"]);
        setImagen({id_archivo: json["blog"]["id_archivo"], ruta : json["blog"]["ruta"]});
        setCategorias(json["blog"]["categorias"]);
        setPublicado(json["blog"]["publicado"] == 'true');
        const content = convertFromRaw(JSON.parse(json["blog"]["data"]));
        setEditorState(EditorState.createWithContent(content));
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function guardar(){
    if(!save){
      return false
    }
    setSave(false);
    const content = convertToRaw(editorState.getCurrentContent());
    blog.data = JSON.stringify(content);
    let descripcion = '';
    let link = '';
    content.blocks.some(block=>{
      console.log(block.text, block.text.split(" "));
      if(block.text.split(" ").length==0 || block.text.split(" ")[0]==''){
        return false;
      }
      return block.text.split(" ").some(texto=>{
        if(descripcion.length + texto.length < 100){
          descripcion+= texto + ' ';
          return descripcion.length >= 100;
        }
        else{
          return true;
        }
      })
    })
    blog.link=(blog.titulo || "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, '').replaceAll(" ", "-");
    blog.descripcion=descripcion;
    blog.id_archivo = imagen.id_archivo;
    blog.categorias = categorias;
    console.log(blog)
    // return false
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/post.php', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      },
      body: JSON.stringify(blog)
    }).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json)
      if(json["errAuth"]){
        // setToken({})
        return false;
      }
      alert("Se ha guardado con éxito.")
      setSave(true);
      if(blog.id_blog==0){
        blog.id_blog = json["id_blog"];
        window.history.replaceState(null, blog.titulo, json["id_blog"])
      }
    })
    .catch((error) => {
      console.log(error);
      setSave(true);
    });
  }

  function eliminar(id_blog){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/delete.php?id_blog='+id_blog, 
      {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      if(json["errAuth"]){
        setToken({})
        return false;
      }
      if(json["status"]){
        navigate('/admin/')
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function publicar(e){
    console.log("publicando")
    let publicadoAux = !e.target.checked;
    if(window.confirm("¿Deseas cambiar el estado de este blog de '"+(publicadoAux ? "" : "No ")+"Publicado' a '"+(publicadoAux ? "No " : "")+"Publicado'?")){
      fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/publicar.php?id_blog='+blog.id_blog+"&publicado="+(!publicadoAux),
        {
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      ).then((response) => {/*console.log(response.text());*/return response.json()})
      .then((json) => {
        console.log(json);
        if(json["errAuth"]){
          setToken({})
          return false;
        }
        if(json["status"]){
          blog.publicado = !publicadoAux;
          e.target.checked = !publicadoAux;
          setPublicado(!publicadoAux);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    else{
      e.target.checked = publicadoAux;
    }
  }

  function subirImg(e){
    let input = e.currentTarget;
    if(!input.files.length)
      return false;
    let data = new FormData();
    data.append('imagen', input.files[0]);
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/subirImagen.php?token=', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      },
      body: data
    }).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      if(json["errAuth"]){
        // setToken({})
        return false;
      }
      setImagen({id_archivo:json["id_archivo"], ruta:json["ruta"]});
    })
    .catch((error) => {
      console.log(error);
    });
    e.target.value='';

  }
  async function uploadCallback(file){
    let data = new FormData();
    data.append('imagen', file);
    var ruta = await fetch('https://www.alpha-fullcommerce.mx/alphaAPI/subirImagen.php?token=', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`, // notice the Bearer before your token
      },
      body: data
    }).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      if(json["errAuth"]){
        // setToken({})
        return false;
      }
      return json["ruta"];
    })
    .catch((error) => {
      console.log(error);
    });

    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: 'https://www.alpha-fullcommerce.mx/alphaAPI/img/'+ruta } });
      }
    );

  }

  function agregarCat(categoria){
    if(categoria){
      setCategorias([...categorias, categoria]);
      return true;
    }
    let nueva = inputCat.current.value;
    if(!inputCat.current.reportValidity()){
      return false;
    }
    let novalido = categorias.some(categoria=>{
      if(categoria.categoria==nueva){
        inputCat.current.setCustomValidity("Esta categoría ya ha sido asignada");
        inputCat.current.reportValidity();
        setTimeout(()=>{
          inputCat.current.setCustomValidity("");
        },2000)
        return true;
      }
    })
    if(novalido)
      return false;
    setCategorias([...categorias, {categoria: nueva}]);
    inputCat.current.value='';
  }

  async function buscarSugerencia(e){
    let texto = e.target.value;
    if(texto==""){
      setSugerencias([]);
      return false;
    }
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/getSugerencias.php?texto='+texto, 
      {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      if(json["errAuth"]){
        setToken({})
        return false;
      }
      if(json["sugerencias"]){
        setSugerencias(json["sugerencias"].filter(sugerencia=>{
          return !categorias.some(categoria=>{
            return categoria.id_categoria == sugerencia.id_categoria
          })
        }));
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className='editorBlog'>
      <div className='opcBlog'>
        {blog.id_blog!=0 &&
          <>
            <span className='delete-button' onClick={()=>{
              if(window.confirm('¿Estás seguro que deseas eliminar este blog?')){
                eliminar(blog.id_blog)
              }
            }}>Eliminar</span>
            <label className='conSwitch'>
              <span>Publicado</span>
              <input type='checkbox' checked={publicado} onChange={publicar}/>
              <span>
                <div className='bola'></div>
              </span>
            </label>
            <Link to={'/admin/blog/previa/'+blog.id_blog}>Vista Previa</Link>
          </>
        }
        <span className='sinPad' onClick={guardar}>Guardar</span>
      </div>
      <div className='datGral'>
        <div>
          <div>
            <span>Título: </span>
            <input className='minChat' type='text' placeholder='Título' defaultValue={blog?.titulo} onChange={(e)=>{
              blog.titulo = e.target.value;
            }}/>
          </div>
          <div>
            <span>Autor: </span>
            <input className='minChat' type='text' placeholder='Autor' defaultValue={blog?.autor} onChange={(e)=>{
              blog.autor = e.target.value;
            }}/>
          </div>
          <div>
            <div>
              <span>Categorias: </span>
              <span className='autocompleta'>
                <input className='minChat' type='text' placeholder='Categorías' ref={inputCat} required onKeyDown={(e)=>{
                  if(e.key == "Enter"){
                    agregarCat();
                  }
                  else if(e.key == "ArrowDown"){
                    e.preventDefault();
                    document.querySelector(".listaAuto>div:first-child")?.focus();
                  }
                }}
                onChange = {buscarSugerencia}
                />
                {sugerencias.length!=0 &&
                  <div className='listaAuto'>
                    {sugerencias.map((sugerencia, i)=>{
                      return (
                        <div tabIndex={0}
                        onClick={(e)=>{
                          agregarCat(sugerencia);
                          setSugerencias(sugerencias=>{
                            return sugerencias.filter(s=>{
                              return s.id_categoria!=sugerencia.id_categoria;
                            })
                          })
                        }}
                        onKeyDown={(e)=>{
                          if(e.key=="Enter"){
                            agregarCat(sugerencia);
                            setSugerencias(sugerencias=>{
                              return sugerencias.filter(s=>{
                                return s.id_categoria!=sugerencia.id_categoria;
                              })
                            })
                            document.querySelector(".listaAuto>div:first-child")?.focus();
                          }
                          else if(e.key == "ArrowDown"){
                            e.preventDefault();
                            if(e.target.nextElementSibling)
                              e.target.nextElementSibling.focus();
                          }
                          else if(e.key == "ArrowUp"){
                            e.preventDefault();
                            if(e.target.previousElementSibling)
                              e.target.previousElementSibling.focus();
                          }
                        }}>
                          {sugerencia.categoria}
                        </div>
                      )
                    })}
                  </div>

                }
              </span>
              <input className='sinPad' type='button' onClick={agregarCat} value='Agregar'/>
            </div>
            <div className='categorias'>
              {categorias.map((categoria, i)=>{
                return(
                  <span key={i}>
                    <span>{categoria.categoria}</span>
                    <span onClick={()=>{
                      let aux = categorias;
                      aux.splice(i, 1);
                      setCategorias([...aux]);
                    }}>x</span>
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <div>
            <span>Miniatura: </span>
            <label className='prevImg'>
              <input type='file' accept='image/*' onChange={subirImg}/>
              <img src={imagen.id_archivo ? 'https://www.alpha-fullcommerce.mx/alphaAPI/img/'+imagen.ruta : subir}/>
            </label>
          </div>
        </div>
      </div>
      <div>
        <span>Imagen: </span>
        <label className='prevImg'>
          <input type='file' accept='image/*' onChange={subirImg}/>
          <img src={imagen.id_archivo ? 'https://www.alpha-fullcommerce.mx/alphaAPI/img/'+imagen.ruta : subir}/>
        </label>
      </div>
      <div className='editor'>
        <Editor
          toolbar={{ image: { uploadCallback: uploadCallback, previewImage: true}}}
          editorState={editorState}
          onEditorStateChange={setEditorState}
        />
      </div>
    </div>
  );
}

export default Blog;
