import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useToken from '../useToken';
import './Admin.css';
import Login from '../Login/Login.js';
import defaultImg from '../img/logoAlpha.svg';


function Admin() {

  const { token, setToken } = useToken();
  const [blogs, setBlogs] = useState([]);
  useEffect(()=>{
    getData();
  },[])
  async function getData(){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/get.php', 
      {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      if(json["errAuth"]){
        setToken({})
        return false;
      }
      setBlogs(json["blogs"]);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  function eliminar(id_blog){
    fetch('https://www.alpha-fullcommerce.mx/alphaAPI/blogs/delete.php?id_blog='+id_blog, 
      {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    ).then((response) => {/*console.log(response.text());*/return response.json()})
    .then((json) => {
      console.log(json);
      if(json["errAuth"]){
        setToken({})
        return false;
      }
      if(json["status"]){
        setBlogs(blogs=>{
          return blogs.filter(blog=>{
            return blog.id_blog!=id_blog
          })
        })
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  return (
      <div className='blogsAdm'>
      {blogs.length==0 &&
        <div>
          <div>Aún no has creado ningún blog.</div>
          <Link to='blog/'>Crear Blog</Link>
        </div>
      }
      { 
        blogs?.map(blog=>{
          return (
            <div className='tarBlog' key={blog.id_blog}>
              <div className='imgBlog'>
                <img src={blog.ruta ? 'https://www.alpha-fullcommerce.mx/alphaAPI/img/'+blog.ruta : defaultImg}/>
              </div>
              <div className='titB'>{blog.titulo}</div>
              <div className='desB'>{blog.descripcion}…</div>
              <div className='botonB'>
                <Link to={'blog/'+blog.id_blog}>Editar</Link>
                <span className='delete-button' onClick={()=>{
                  if(window.confirm('¿Estás seguro que deseas eliminar este blog?')){
                    eliminar(blog.id_blog)
                  }
                }}>Eliminar</span>
              </div>
            </div>
          )
        })
      }
      </div>
    );
}

export default Admin;
