import { Link } from 'react-router-dom';
import './Ambiente.css';
import logo from '../logo.svg';
import logoAlpha from '../logoAlpha.svg';
import social from '../img/social.svg';
import trans from '../img/trans.svg';
import medioA from '../img/medioA.svg';
import foto4 from '../img/foto4.svg';
import foto5 from '../img/foto5.svg';

function Ambiente() {
  return (
    <div className='medAmbiente ver'>
        <h1 className='titPrincipal'>Responsabilidad Social y Medio Ambiente</h1>
      <div className='textoC'>
        <p>Alpha FullCommerce MX es una empresa comprometida con la responsabilidad social y el cuidado del medio ambiente. Reconocemos la importancia de nuestras acciones y su impacto en la sociedad y el entorno en el que operamos. Nos esforzamos por promover la sostenibilidad ambiental a través de prácticas comerciales responsables, como la implementación de energías renovables, la gestión eficiente de recursos y la reducción de residuos. Nuestro objetivo es contribuir a la conservación del medio ambiente y minimizar nuestra huella ecológica.

</p><p>Además, valoramos el bienestar de la comunidad y nos comprometemos con iniciativas sociales. Colaboramos con organizaciones sin fines de lucro, participamos en programas de voluntariado y apoyamos proyectos educativos y de desarrollo de habilidades. Nos preocupamos por el crecimiento sostenible de las comunidades en las que operamos y buscamos marcar una diferencia positiva en la vida de las personas. Nuestro compromiso es actuar con integridad, transparencia y respeto en todas nuestras operaciones, promoviendo la diversidad y la inclusión en nuestro equipo y nuestras prácticas comerciales.</p>
        </div>
     <section>
       <div className='titSeccion'>Acciones y Programas</div>
       <div className='tarNueva'>
         <div className='imgDon'>
          <img className='imgInicio' src={medioA}/>
          </div>
         <div> 
          <div className='titNT'>Sostenibilidad Ambiental</div>
          <div> 
            <p>Implementamos medidas para minimizar nuestro impacto ambiental, como:</p>
            <ul>
              <li>Uso de energías renovables y eficiencia energética en nuestras instalaciones.</li>
              <li>Reciclaje y gestión responsable de residuos.</li>
              <li>Promoción del uso de transporte sostenible y reducción de emisiones.</li>
            </ul>
          </div>
         </div>
       </div>
        <div className='tarNueva'>
         <div className='imgDon'>
          <img className='imgInicio' src={social}/>
          </div>
         <div> 
          <div className='titNT'>Responsabilidad Social</div>
          <div> 
            <p>Nos comprometemos a contribuir al bienestar de la comunidad y promover la equidad social mediante:</p>
            <ul>
              <li>Participación activa en programas de voluntariado y donaciones a organizaciones sin fines de lucro.</li>
              <li>Apoyo a la educación y desarrollo de habilidades en comunidades locales.</li>
              <li>Fomento de la diversidad e inclusión en nuestro equipo y prácticas de contratación.</li>
            </ul>
          </div>
         </div>
       </div>


        <div className='tarNueva'>
         <div className='imgDon'>
          <img className='imgInicio' src={trans}/>
          </div>
         <div> 
          <div className='titNT'>Transparencia y Rendición de Cuentas</div>
          <div> 
            <p>En Alpha FullCommerce MX, nos comprometemos a ser transparentes sobre nuestras acciones y a rendir cuentas ante nuestros stakeholders y la sociedad. Publicamos informes periódicos sobre nuestras iniciativas de responsabilidad social y medio ambiente, compartiendo los resultados y metas alcanzadas.</p>
  
          </div>
         </div>
       </div>
     </section>
    </div>
  );
}

export default Ambiente;
